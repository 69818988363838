import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'

import styles from './index.module.scss'


const CompanyPage = () => {

    return (
        <div>
            <SEO title="会社概要" />
            <Header hasBack={false} />

            <Mainview className={styles.mainview}>
                <div className={styles.mainimage}>
                    <h1><img src="/img/company/logo.png" alt="WE ARE THE FIT" /></h1>
                </div>
            </Mainview>

            <section className={styles.concept}>
                <div>
                    <h2>我々の思い</h2>
                    <p>
                        「ワークアウトの無い、人生なんて。」<br />
                        体を動かすことは、人間にとっての原点、<wbr />
                        基本である。<br />
                        体が動けば、心も軽くなる。<wbr />
                        気持ちも前を向く。<br />
                        ハードな毎日は楽しめばいい。<wbr />
                        悩んでいる時は動けばいい。<br />
                        我々は単なるジムでは無い。<br />
                        体を動かすあなたの生きる目的をつくる<wbr />
                        場所でありたい。<br />
                        健康でアクティブな生き方の味方をしたい。<br />
                        そして、あなたと体を動かす喜びを<wbr />
                        分かち合いたい。
                    </p>
                </div>
            </section>

            <section className={styles.company}>
                <div>
                    <h2>COMPANY</h2>
                    <em>会社概要</em>
                    <dl>
                        <dt>会社名</dt>
                        <dd>快適ライフサービス株式会社</dd>
                    </dl>
                    <dl>
                        <dt>所在地</dt>
                        <dd>〒152-0031　東京都目黒区中根2-12-10</dd>
                    </dl>
                    <dl>
                        <dt>設立</dt>
                        <dd>1983/6/27</dd>
                    </dl>
                    <dl>
                        <dt>代表取締役</dt>
                        <dd>小林將一</dd>
                    </dl>
                </div>
            </section>
        </div>
    )
}

export default CompanyPage
